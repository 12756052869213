import { Box } from "rebass";

export default function Journal_3() {
  return (
    <Box css={{whiteSpace: 'pre-wrap', lineHeight: 1.6}}>
      {
`Lying on the grass, I found myself beneath the night sky, gazing at the moon. The celestial body, an object of fascination, demanded my attention. Despite the allure of its beauty, my scientific mind couldn't help but analyze the play of light and shadows across its surface. I pondered the gravitational forces at play and the intricate dance between Earth and its only natural satellite. The cool grass beneath me became a makeshift laboratory floor as I mentally dissected the moon's mysteries, losing myself in the complexities of astrophysics. As my eyes traced the moon's contours, my thoughts swirled with equations and calculations, unable to detach from the scientific wonder even during a moment of simple stargazing.

Dreamt of Gensokyo, a realm beyond the mundane, where reality blurred into fantasy. Amidst cherry blossoms and enigmatic beings, equations dissolved into the enchantment of the unknown. In the dream's embrace, the scientific mind yielded to the magic of this mystical land, and I became part of the fantastical tapestry of Gensokyo's existence.
`
      }
    </Box>
  )
}