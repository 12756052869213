import { Box } from "rebass";

export default function CS1() {
  return (
    <Box css={{ whiteSpace: "pre-wrap", lineHeight: 1.6 }}>
      Recently I read a blog about operating systems written in{" "}
      <strong>Rust</strong>. I was inspired by the blog and decided to write my
      own operating system in <strong>Rust</strong>. I have been working on it
      for days and I have achieved some progress:)
      <br />
      For fun I just made a simple kernel with <strong>
        vga text buffer
      </strong>{" "}
      and a simple interrupt handler. For readers who are interested in this ,
      you can download the source here and the workable image here.
    </Box>
  );
}
