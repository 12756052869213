import { Box, Flex, Heading, Image } from "rebass";
import { useState } from "react";
import { title } from "../providers/StyleSet";

export default function Photography() {
  let [image, setImage] = useState(-1);
  let scale = 1;
  let onWheel = (e) => {
    let s = e.deltaY / Math.abs(e.deltaY);
    e.target.style.scale = `${(scale -= s * 0.06)}`;
  };
  return (
    <>
      <Heading fontFamily={title.font} fontSize={5} p={3}>
        Gallery
      </Heading>
      {image === -1 || (
        <Box
          css={{ position: "fixed" }}
          bg="#31304DAA"
          width="100vw"
          height="100vh"
        >
          <Image
            css={{
              position: "absolute",
              left: "50%",
              top: "40%",
              transform: "translate(-50%, -50%)",
            }}
            src={`astrophotography/images (${image}).jpeg`}
            width="50vw"
            onWheel={onWheel}
            onClick={() => setImage(-1)}
            m={3}
          />
        </Box>
      )}
      <Flex
        flexWrap="wrap"
        flexDirection="row"
        height="100%"
        pb={6}
        px={3}
        overflowY="scroll"
        justifyContent="space-between"
      >
        {Array.from({ length: 24 }, (_, i) => i).map((i) => (
          <Image
            key={i.toString()}
            css={{ borderRadius: 8 }}
            src={`astrophotography/images (${i}).jpeg`}
            onClick={() => setImage(i)}
            m={2}
          />
        ))}
      </Flex>
    </>
  );
}
