import { Box, Text, Flex } from "rebass";

import Data from "./providers/Data";
import { useState } from "react";
import Home from "./pages/Home";
import Paper from "./pages/Paper";
import Colors from "./providers/Colors";
import Photography from "./pages/Photography";
import Contact from "./pages/Contact";

const pages = [<Home />, <Photography />, <Paper />, <Contact />];

export default function App() {
  let [index, setIndex] = useState(0);
  return (
    <Flex bg={Colors.bg} overflowY='clip'>
      <Box width={5 / 6} height="100vh">
        {pages[index]}
      </Box>
      <Flex bg={Colors.side} flexDirection="column" width={1 / 6}>
        {Data.map(({ title }, i) => (
          <Text
            key={i.toString()}
            fontWeight={i === index && "bold"}
            bg={i === index && Colors.side_button}
            color="white"
            onClick={() => setIndex(i)}
            p={3}
          >
            {title}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
}
