import { Flex, Heading } from "rebass";
import TextAreaFolder from "../components/TextAreaFolder";
import Journal1 from "./posts/Journal_1";
import Journal2 from "./posts/Journal_2";
import Journal3 from "./posts/Journal_3";
import CS1 from "./posts/CS1";
import { title } from "../providers/StyleSet";
import { useState } from "react";

export default function Paper() {
  let [index, setIndex] = useState(-1)

  return (
    <Flex flexDirection="column" overflowY="scroll" height="100vh">
      <Heading fontFamily={title.font} fontSize={5} p={3}>
        Posts
      </Heading>
      {
        [
          ["Journal #1", <Journal1 />],
          ["Journal #2", <Journal2 />],
          ["Journal #3", <Journal3 />],
          ["CS #1", <CS1 />]
        ].map((s, i) => 
          <TextAreaFolder 
            header={s[0]} 
            content={s[1]} 
            fold={index !== i} 
            onClick={() => setIndex(index === i ? -1 : i)}
          />
        )
      }
    </Flex>
  );
}
