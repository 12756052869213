import { Card } from "rebass";
import Colors from "../providers/Colors";

export default function TextArea({ children }) {
  return (
    <Card
      bg={Colors.area}
      css={{ borderRadius: 4, whiteSpace: "pre-wrap" }}
      mb={4}
      p={3}
      ml={3}
      mr={6}
    >
      {children}
    </Card>
  );
}
