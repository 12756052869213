import { Box, Button, Card, Flex, Heading } from "rebass";
import Colors from "../providers/Colors";
export default function TextAreaFolder({ header, content, onClick, fold }) {
  return (
    <Card
      bg={Colors.area}
      css={{ borderRadius: 4, whiteSpace: "pre-wrap" }}
      mb={4}
      p={3}
      ml={3}
      mr={6}
    >
      s
      <Flex alignItems="end">
        <Box width={11 / 12} pb={fold || 4}>
          <Heading mb={fold && 2} color={fold || "grey"}>
            {header}
          </Heading>
          {fold || content}
        </Box>
        <Button
          bg={Colors.side_button}
          width={1 / 12}
          minWidth="80px"
          onClick={onClick}
        >
          {fold ? "Expand" : "Fold"}
        </Button>
      </Flex>
    </Card>
  );
}
