import { Heading, Flex } from "rebass";
import TextArea from "../components/TextArea";
import { title } from "../providers/StyleSet";

export default function Home() {
  return (
    <Flex flexDirection="column">
      <Heading fontFamily={title.font} fontSize={6} p={3}>
        Welcome to Manifold
      </Heading>
      <TextArea>
        <Heading mb={2}>About Me</Heading>
        My name is <strong>Usami Renko</strong>. I have been updating this
        website since I was graduated from junior high school. I am a girl that
        is crazy about physics but it may be rare if you wanna see something
        about this on this site. So far, I haven't ever allowed anyone to be
        here except my best friend.
      </TextArea>

      <TextArea>
        <Heading mb={2}>Topics</Heading>
        This website contains a variety of topics, including but not limited to:
        <ul>
          <li key="1">
            <strong>Photography:</strong> I am a big fan of photography,,
            especially in space. I have a collection of space photos that I have
            taken over the years.
          </li>
          <li key="2">
            <strong>String Theory</strong> I love to explore the universe and
            its mysteries, which pushes me to the study of string theory. This
            website includes a collection of articles and papers that I have
            written on the subject.
          </li>
          <li key="3">
            <strong>Programming</strong> I am a self-taught programmer and I
            have learnt operating systems, type theory and programming language
            theory. My favorite programming language is <strong>Haskell</strong>
            .
          </li>
        </ul>
      </TextArea>
    </Flex>
  );
}
