import { Heading, Flex } from "rebass";
import TextArea from "../components/TextArea";
import { title } from "../providers/StyleSet";

export default function Contact() {
  return (
    <Flex flexDirection="column">
      <Heading fontFamily={title.font} fontSize={5} p={3}>
        Contact
      </Heading>
      <TextArea>Email: ?</TextArea>
      <TextArea>Email: ?</TextArea>
    </Flex>
  );
}
