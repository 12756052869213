const obj = [
  {
    title: "Home",
  },
  {
    title: "Gallery",
  },
  {
    title: "Posts",
  },
  {
    title: "Contact",
  },
];

export default obj;
